// eslint-disable-next-line
export default url => {
  var tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('target', '_self');

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}
