import { hasValidJwt, login, getJwt } from './util/auth';
import { get } from './util/request';

const logOutPath = 'SignIn.php?YT1zaWdub3V0';

const loadUser = async () => {
    try {
        const userData = await get('/users');

        if (userData) {
            userData.forEach((user) => {
                if (user.userId === window.auth.userData.sub) {
                    window.auth.userData.name = user.name;
                    window.auth.userData.mail = user.email;
                }
            });
        }
    } catch (error) {
        window.auth.userData.name = 'Unknown';
        window.auth.userData.mail = 'Unknown';
    }
}

switch (window.location.pathname) {
    case '/':
        window.location = '/insights';
        break;
    case '/insights/auth-logout':
        const { origin } = window.location;
        const logOutUrl = `${origin}/${logOutPath}`;

        window.location.replace(logOutUrl);
        break;
    default:
        // Don't bother authenticating on shared reports.
        if (/^\/insights\/shared-report\//.test(window.location.pathname)) {
            const [ ,,, companyId,, shareToken
            ] = window.location.pathname.split('/');
            window.auth = {
                headers: {
                    authorization: `Bearer SHARE/${companyId}/${shareToken}`
                },
                shareToken: true,
                userData: {
                    co: companyId
                }
            };
            import('./loadShareApp'); // eslint-disable-line
            break;
        }

        import('./loadLoadingScreen'); // eslint-disable-line

        if (!hasValidJwt()) {
            login().catch((error) => {
                console.error(error);
                window.location = `/`
            });
        } else {
            const jwt = getJwt();

            window.auth = {
                userData: {
                    sub: jwt.sub.toString(),
                    name: '',
                    mail: '',
                    co: jwt.co
                }
            };

            loadUser();

            import('./loadReactApp'); // eslint-disable-line
        }

        break;
}
