import down from './download';
import { login, getCookieValue } from './auth';

export function buildUrl(url) {
  return `${process.env.REACT_APP_API_SERVER_URI}${url}`;
}

async function request(url, method, body) {
  const params = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };

  if (window.auth.headers) {
    params.headers = window.auth.headers;
  } else {
    const jwt = getCookieValue('jwtv2');

    if (jwt) {
      params.headers['Authorization'] = `Bearer ${jwt}`;
    }
  }

  if (body) {
    params.headers['Content-Type'] = 'application/json';
    params.body = JSON.stringify(body);
  }

  const response = await fetch(buildUrl(url), params);

  if (!response.ok) {
    if (response.status === 401) {
      if (window.auth.shareToken) {
        throw new Error('Invalid sharing link. Please check the link and try again. Access may have been revoked.');
      }

      await login().catch(e => console.error(e));
    }

    const parsedResponse = await response.json();
    const err = new Error(parsedResponse
      ? parsedResponse.message
      : `HTTP-${response.status} ${response.statusText}`);
    err.res = response;
    err.parsedResponse = parsedResponse;
    throw err;
  }

  //had to use a post method for bulk delete
  //and it was causing weird 'Unexpected end of input' json errors
  if (method === 'POST' && response.status === 204) return {}

  if (method !== 'DELETE') {
    return response.json();
  }
}

export function get(url) {
  return request(url, 'GET');
}
export function post(url, body) {
  return request(url, 'POST', body);
}
export function put(url, body) {
  return request(url, 'PUT', body);
}
export function del(url) {
  return request(url, 'DELETE');
}

export function download(url) {
  down(url);
}
