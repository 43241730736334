/* eslint-disable no-console */
import jwt_decode from 'jwt-decode';
import { get, indexOf, floor } from 'lodash';
import axios from 'axios';

const errorRedirect = (msg) => {
  window.location = `/insights/error.html?message=${encodeURIComponent(msg)}`;
};

const getCookieValue = (a) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

const getJwt = () => {
  const jwt = getCookieValue('jwtv2');
  if (jwt !== '') {
    return jwt_decode(jwt);
  }
  return null;
};

// Token has expired, use user & pass cookies to login
const login = async () => {
  const user = decodeURIComponent(getCookieValue('user'));
  const password = decodeURIComponent(getCookieValue('pass'));

  const params: any = {
    method: 'POST',
    headers: {
      accept: 'application/json',
    },
  };

  const result = await axios({
    ...params,
    url: '/api/v1/auth',
    data: { user, password },
  });

  if (result.status !== 200 || !result.data || !result.data.token) {
    window.location.href = '/bo/SignIn.php';
  }
};

const hasValidJwt = () => {
  const jwt = getJwt();

  if (jwt !== null && jwt.exp && jwt.type) {
    return jwt.type === 'user' && (jwt.exp > floor(Date.now() / 1000));
  }

  return false;
};

const hasPermission = (permission) => {
  return hasValidJwt() && indexOf(get(getJwt(), 'perms'), permission) !== -1
}

const requirePermission = (permission) => {
  if (!hasPermission(permission)) {
    throw new Error(
        'You do not have permission to access this page',
    );
  }
};

const storeGet = (key): any => (('localStorage' in window) ? localStorage.getItem(key) : getCookieValue(key));

const storeSet = (key, value) => {
  if ('localStorage' in window) localStorage.setItem(key, value);
  document.cookie = `${key}=${value}`;
};

export {
  getCookieValue,
  hasValidJwt,
  getJwt,
  errorRedirect,
  requirePermission,
  hasPermission,
  storeSet,
  storeGet,
  login,
};
